<template>
  <div>
    <h1 class="mt-3">
      Итоги года {{ lang }}
    </h1>

    <h2>
      {{ stage }}
    </h2>

    <h3>Альбомы</h3>

    <b-row class="match-height" v-if="albumBaskets && albumBaskets.length">
      <b-col
          lg="4"
          md="6"
          v-for="basket in albumBaskets"
          :key="basket.id"
      >
        <basket-item :basket="basket" type="album" @updateList="loadBaskets()"/>
      </b-col>
    </b-row>

    <div v-else>
      Нет альбомов
    </div>

    <h3>Песни</h3>

    <b-row class="match-height" v-if="songBaskets && songBaskets.length">
      <b-col
          lg="4"
          md="6"
          v-for="basket in songBaskets"
          :key="basket.id"
      >
        <basket-item :basket="basket" type="song"/>
      </b-col>
    </b-row>

    <div v-else>
      Нет песен
    </div>

    <h3>Клипы</h3>

    <b-row class="match-height" v-if="videoBaskets && videoBaskets.length">
      <b-col
          lg="4"
          md="6"
          v-for="basket in videoBaskets"
          :key="basket.id"
      >
        <basket-item :basket="basket"  type="video"/>
      </b-col>
    </b-row>

    <div v-else>
      Нет клипов
    </div>

  </div>
</template>

<script setup>
import BasketItem from "@/views/year/baskets/BasketItem.vue";
import {BCol, BRow} from "bootstrap-vue";

export default {
  components: {
    BasketItem,
    BCol, BRow
  },
  props: ['lang'],
  data() {
    return {
      songBaskets: [],
      albumBaskets: [],
      videoBaskets: [],
      stage_id: 0,
    }
  },
  methods: {
    loadBaskets: function () {
      this.$http.get('/basket?lang=' + this.lang).then(res => {

        this.songBaskets = res.data.data.filter(function (el) {
          return el.type === 'song';
        });
        this.albumBaskets = res.data.data.filter(function (el) {
          return el.type === 'album';
        });
        this.videoBaskets = res.data.data.filter(function (el) {
          return el.type === 'video';
        });
        this.stage_id = res.data.data[0].stage ?? -1
      })
    }
  },
  computed: {
    stage: function () {
      if (this.stage_id === 1) {
        return "Отборочный раунд"
      }
      if (this.stage_id === 2) {
        return "Полуфинал"
      }
      if (this.stage_id === 3) {
        return "Финал"
      }
      return "Отборочный раунд"
    }
  },
  created() {
    this.loadBaskets();
  },
}
</script>


<style scoped lang="scss">

</style>