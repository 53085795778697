<template>
  <b-card
      class="card-basket"
      n-o-body
      :class="this.isFilled ? 'border-success' : ''"
  >
    <b-card-header>
      <b-card-title>{{ basket.name }}</b-card-title>
    </b-card-header>

    <b-card-body>

      <draggable
          v-model="basket.items"
          handle=".draggable-basket-item-handle"
          class="list-group list-group-flush"
          tag="ul"
      >
        <transition-group
            type="transition"
            name="flip-list"
        >
          <b-list-group-item
              v-for="item in basket.items"
              :key="item.id"
              tag="li"
              class="basket-row-item"
          >
            <div class="basket-outer-row">
              <feather-icon
                  icon="MoreVerticalIcon"
                  class="draggable-basket-item-handle"
              />

              <div class="basket-item-place font-large-1 font-weight-bolder">
                {{ item.place }}
              </div>

              <div class="">
                <div class="basket-item">

                  <b-media no-body>
                    <b-media-aside class="align-self-center">
                      <b-avatar
                          rounded
                          size="42"
                          variant="light-success"
                          :src="cover(item)"
                      >
                      </b-avatar>
                    </b-media-aside>
                    <b-media-aside class="align-self-center basket-item-rating">
                      <b-badge variant="success">{{ item.rating }}</b-badge>
                    </b-media-aside>
                    <b-media-body>
                      <h6 class="basket-title">
                        {{ item.title }}
                      </h6>
                      <small>{{ item.month.title }}</small>
                      <b-link :href="item.url" target="_blank" v-if="item.url" class="ml-1 small">
                        <feather-icon icon="ExternalLinkIcon"/>
                        Ссылка
                      </b-link>
                    </b-media-body>
                  </b-media>
                </div>
              </div>

            </div>

          </b-list-group-item>
        </transition-group>
      </draggable>

    </b-card-body>
    <b-card-footer>
      <b-button
          variant="success"
          @click="saveBasket()"
          :disabled="isSaving || isResetting"
      >
        <b-spinner small v-if="isSaving"/>
        Сохранить
      </b-button>
      <b-button
          variant="danger"
          @click="resetBasket()"
          :disabled="isSaving || isResetting"
          class="ml-1"
      >
        <b-spinner small v-if="isResetting"/>
        Сбросить
      </b-button>
    </b-card-footer>
  </b-card>
</template>

<script setup>

import {
  BAvatar,
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardFooter,
  BMedia,
  BMediaAside,
  BMediaBody,
  BButton,
  BListGroupItem,
  BBadge,
  BSpinner,
  BLink,
} from "bootstrap-vue";
import draggable from 'vuedraggable'

export default {
  components: {
    BAvatar,
    BCard,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardFooter,
    BMedia,
    BMediaAside,
    BMediaBody,
    BButton,
    BListGroupItem,
    BBadge,
    BSpinner,
    BLink,
    draggable,
  },
  props: {
    basket: {
      type: Object,
      required: true
    },
    type: {
      type: String
    },
  },
  data() {
    return {
      isSaving: false,
      isResetting: false,
    }
  },
  methods: {
    saveBasket: function () {
      this.isSaving = true
      this.$http.put(`/basket/${this.basket.id}/vote`,
          {
            items: this.basket.items,
          },
      ).then(res => {
        this.isSaving = false
        this.renumerate()
      }).catch(function (error) {
        console.error(error);
        this.isSaving = false
      });
    },
    resetBasket: function () {
      this.isResetting = true
      this.$http.put(`/basket/${this.basket.id}/vote`,
          {
            reset: true,
            items: []
          },
      ).then(res => {
        this.isResetting = false
        this.resetNumeration()
      }).catch(function (error) {
        console.error(error);
        this.isResetting = false
      });
    },
    cover: function (item) {
      if (item.cover) {
        return item.cover
      } else if ((this.type === 'video') && (item.youtube_id)) {
        return 'https://i.ytimg.com/vi/' + item.youtube_id + '/hqdefault.jpg'
      }
      return null
    },
    renumerate: function () {
      let place = 1;
      this.basket.items.forEach((obj, index) => {
        obj.place = index + 1;
      });
    },
    resetNumeration: function () {
      this.basket.items.forEach((obj, index) => {
        obj.place = null;
      });
    },
  },
  computed: {
    isFilled: function () {
      if (this.basket.items && this.basket.items[0] && this.basket.items[0].place) {
        return true
      }
      return false
    }
  },
}
</script>

<style scoped lang="scss">
.card-basket {
  .basket-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    max-width: 27rem;

    &:not(:last-child) {
      margin-bottom: 1.2rem;
    }

    .avatar {
      margin-right: 1.2rem;

      .avatar-content {
        width: 42px;
        height: 42px;
      }
    }

    .basket-title {
      margin-bottom: 0.2rem;
      margin-top: 0.2rem;
    }
  }
}

.draggable-basket-item-handle {
  position: absolute !important;
  left: 4px;
  top: 50%;
  transform: translateY(-50%);
  visibility: visible;
  cursor: move;
  justify-self: start;

  .card-basket .basket-row-item:hover & {
    visibility: visible;
  }
}

.basket-row-item {
  padding-left: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: start;
}

.basket-outer-row {
  display: flex;
  align-items: center;
  justify-content: start;
}

.basket-item-place {
  width: 3rem;
  justify-self: start;
}

.basket-item-rating {
  width: 2rem;
}
</style>